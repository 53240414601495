import React from "react";

import { Container, Row, Col, Card } from "reactstrap";
// import CountUp from 'react-countup';

import "react-circular-progressbar/dist/styles.css";

function AboutPoints() {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        className="section py-5"
        style={{
          backgroundColor: "#f0f4f8",
        }}
      >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-dark" md={12}>
              <h1
                className="text-700 text-center"
                style={{
                  fontSize: size ? "3rem" : "2rem",
                  color: "#0696d7",
                }}
              >
                Why Attend
              </h1>
            </Col>
            {content.map((c) => (
              <Col md={4}>
                <Card
                  className="p-3 text-center"
                  style={{ minHeight: "150px" }}
                >
                  <img
                    src={require(`assets/icons/${c.image}`)}
                    style={{ maxWidth: "100px" }}
                    alt="about"
                    className="mx-auto mb-4"
                  />
                  <p className="text-400">{c.title}</p>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default AboutPoints;

const content = [
  { title: "Better handover experience", image: "8.png" },
  { title: "Enhanced stakeholder engagement", image: "9.png" },
  { title: "Greater cost control and ROI", image: "10.png" },
];
