import React from "react";
import { Container, Row, Col } from "reactstrap";
import Card from "reactstrap/lib/Card";

function TextGrid() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section py-3"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row className="justify-content-center">
            <Col
              md={6}
              className={size ? "border-right" : "border-bottom"}
              style={{ padding: "20px" }}
            >
              <h1
                className="text-700 m-0 my-4"
                style={{
                  fontSize: size ? "3rem" : "2rem",
                  color: "#0696d7",
                }}
              >
                Event
                <br />
                Summary
              </h1>
              <p className="text-400">
                With construction projects demanding increased efficiencies,
                Building Information Modeling (BIM) continues to address these
                requirements across the Architecture, Engineering and
                Construction value chain.
                <br />
                <br />
                But to date, the main beneficiaries of BIM who are Building
                Owners, have not sufficiently tapped into the direct benefits of
                BIM to their projects.
                <br />
                <br />
                If you are a Building Developer/Owner, please do attend this
                exclusive Virtual Event to understand how your business is able
                to leverage the benefits of BIM.
              </p>
            </Col>
            <Col md={6} style={{ padding: "20px" }}>
              <h1
                className="text-700 m-0 my-4"
                style={{
                  fontSize: size ? "3rem" : "2rem",
                  color: "#0696d7",
                }}
              >
                Key
                <br />
                Takeaways
              </h1>
              <p className="text-400">
                <b>As a Building Owner</b> Understand real-time advantages of BIM
                for your business:
              </p>
              <div className="container row justify-content-center">
                {content.map((c) => (
                  <Col xs={12}>
                    <Card className={!size && "text-center"}>
                      <div className="container-fluid row ml-1 py-2">
                        <Col lg={2} className="align-self-center px-0">
                          <img
                            src={require(`assets/icons/${c.image}`)}
                            style={{ maxWidth: "100px" }}
                            width="100%"
                            alt="about"
                            className="mx-auto"
                          />
                        </Col>
                        <Col lg={10} className="align-self-center">
                          <p className="text-400 mt-2">{c.title}</p>
                        </Col>
                      </div>
                    </Card>
                  </Col>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;

const content = [
  {
    title: "See how the benefits of BIM can impact your bottom line",
    image: "5.png",
  },
  {
    title:
      "Understand what a BIM-mandate is and why you need one for your projects",
    image: "6.png",
  },
  {
    title:
      "Explore the real-world application of Digital Twins on a local South Africa project",
    image: "7.png",
  },
];
