import React from "react";
import { Row, Container, Col } from "reactstrap";

function DemoFooter() {
  return (
    <footer className="footer footer-black footer-white ">
      <Container fluid>
        <Row>
          <Col lg={1} xs={0} />
          <Col lg={4} style={{ paddingTop: "3rem" }}>
            <p>Organised By</p>
            <a href="/">
              <img
                src={require("assets/images/logo/3.png")}
                alt="vibe"
                style={{ maxWidth: "150px" }}
              />
            </a>
            <br />
            <br />
            <p>Sponsored By</p>
            <a href="https://www.autodesk.co.za/">
              <img
                src={require("assets/images/logo/autodesk.png")}
                alt="vibe"
                style={{ maxWidth: "200px" }}
              />
            </a>
          </Col>
          <Col lg={3} className={"text-left"} style={{ padding: "3rem" }}>
            <p>
              <a href="/" style={{ color: "#333333" }}>
                Home
              </a>
            </p>
            <p>
              <a href="/#about" style={{ color: "#333333" }}>
                About
              </a>
            </p>
            <p>
              <a href="/#agenda" style={{ color: "#333333" }}>
                Agenda
              </a>
            </p>

            <p>
              <a
                href="http://www.autodesk.com/privacy"
                style={{ color: "#333333" }}
              >
                Privacy-Policy Autodesk
              </a>
            </p>
            <p>
              <a
                href="https://saifee-events.com/privacy-policy"
                style={{ color: "#333333" }}
              >
                Privacy-Policy Saifee-Events
              </a>
            </p>
            
            <p>
              <a
                href="https://www.autodesk.com/company/legal-notices-trademarks/access-use/website-terms-of-use"
                style={{ color: "#333333" }}
              >
                Terms of Use
              </a>
            </p>
          </Col>
          <Col lg={4} style={{ padding: "3rem" }}>
            <p>Connect With Us</p>

            {social.map((s) => (
              <a
                key={s.name}
                className={`btn-primary text-900 pt-4 px-2`}
                style={{
                  color: "#1d1960",
                  backgroundColor: "transparent",
                }}
                href={s.link}
              >
                <i
                  className={`fa fa-${s.name}`}
                  style={{ fontSize: "1.5rem" }}
                />
              </a>
            ))}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs={12} className={"text-center pt-10"}>
            <p>© 2021 Saifee-Events. All Rights Reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;

const social = [
  // {
  //   name: "facebook",
  //   link: "",
  // },
  // { name: "twitter", link: "" },
  {
    name: "linkedin",
    link: "https://www.linkedin.com/company/saifee/?viewAsMember=true",
  },
  // {
  //   name: "instagram",
  //   link: "",
  // },
];
