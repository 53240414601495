import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
} from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";

function Agenda() {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;
  function AgendaCard({ data }) {
    return data.map((d, index) => (
      <Col lg={10}>
        <ScrollAnimation
          animateIn="fadeInDown"
          animateOnce={true}
          duration={0.4}
        >
          <Card
            style={{ backgroundColor: "#0696d7" }}
            className="rounded-0 my-1 py-3"
          >
            <CardBody>
              <Container>
                <Row>
                  <Col lg={12} className="align-self-center">
                    <h4 className="text-400 text-white m-0"> {d.time}</h4>
                  </Col>
                  <Col lg={12} className="align-self-center text-white ">
                    <p className="text-700">
                      <b>{d.title}</b>
                    </p>
                    {d.description && (
                      <p
                        className="text-400"
                        dangerouslySetInnerHTML={{ __html: d.description }}
                      />
                    )}
                  </Col>
                </Row>
              </Container>
            </CardBody>
          </Card>
        </ScrollAnimation>
      </Col>
    ));
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#f0f4f8",
        }}
        className="section pt-3 pb-5"
        data-parallax={true}
      >
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <Container>
            <Row>
              <Col md="12">
                <h1
                  className="text-700 text-center"
                  style={{
                    fontSize: size ? "3rem" : "2rem",
                    color: "#0696d7",
                  }}
                >
                  Agenda
                </h1>
                <div className="nav-tabs-navigation">
                  <div className="nav-tabs-wrapper">
                    <h2 className="text-400">
                      <b>26 May </b>(South African Standard Time)
                    </h2>
                  </div>
                </div>
                <TabContent activeTab="1">
                  <TabPane tabId="1">
                    <Container>
                      <Row className="justify-content-center">
                        <AgendaCard data={content1} />
                      </Row>
                    </Container>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
        </ScrollAnimation>
      </div>
    </>
  );
}

const content1 = [
  {
    time: "10:00 - 10:05",
    title: "WELCOME NOTE",
    description: `
    <b>Rory Green</b>,Channel Lead, <b>Autodesk, South Africa</b>
    `,
  },
  {
    time: "10:05 - 10:15",
    title: "OPENING KEYNOTE ADDRESS",
    description: `
    <ul>
    <li>Current state of construction industry in South Africa</li>
<li>Challenges in the Owner portfolio - trends and future prospects</li>
<li>BIM mandates –<ul>
<li>Gauteng’s best practices in Construction digitization</li>
<li>Importance of information flow and the role of Data, Digital Twins,  for Building Owners in management post-construction.</li>
</ul></li>
  </ul>
  SPEAKER:
    <b>Tasneem Motara</b>, Gauteng Member of the Executive Committee, <b>Infrastructure Development & Property Management, South Africa</b>
    `,
  },
  {
    time: "10:15 – 10:40",
    title: "AUTODESK KEYNOTE: Value of BIM for Building Owners",
    description: `
    As a project owner, what do you rely on to guide your decision-making? Building Information Modeling (BIM) enables collaboration and creates data which delivers advantages across the entire value chain. In this session, we will explore the Value and Benefits of BIM for Building Owners.
      <br/><br/>SPEAKER: <b>Vijay Raina</b>, Senior Technical Sales Specialist – <b>AEC, Autodesk, UAE</b><br/>
      `,
  },
  {
    time: "10:40 – 11:20",
    title:
      "PANEL: Connecting the Project Ecosystem through Data and Technology",
    description: `
    The experiences of various project stakeholders on project efficiencies and improved business outcomes, even beyond handover.
We will also discuss project outcomes between BIM Mandated projects and projects without BIM.
      <br/><br/>MODERATOR: <b>Marek Suchocki</b>, Infrastructure Industry Engagement Lead, <b>Autodesk, UK</b><br/><br/>
      PANELISTS: <br/>
      <ul>
      <li> <b>Thomas Fuller</b>, Property Development, <b>Attacq Ltd, South Africa</b></li>
      <li><b>Jarred Pincus</b>, Associate, <b>Paragon Group, South Africa</b></li>
      <li><b>Richard Matchett</b>, Digital Practice Lead – <b>Zutari, South Africa</b></li>
      <li><b>Robert Laubscher</b>, Principal Civil Engineer - Engineering Services Unit, Corporate Services <b>City of Cape Town, South Africa</b></li>
     
      </ul>
      `,
  },
  {
    time: "11:20 - 11:40",
    title: "CUSTOMER KEYNOTE: Thriving Future African Cities- Hatfield Digital Twin Initiative",
    description: `
    <ul>
    <li>Our emerging work here in African Digital Twin Cities</li>
<li>What that will mean for digital asset management in the private and public sector</li>
<li>How we are approaching this from an Open Data, Low-Cost perspective in Africa.</li>
<li>How technology will help us with the Water Twin</li>
<li>How others can start experimenting with Open Data, Low-Costs Approaches themselves</li>

    </ul>
    SPEAKER: <b>Dr Calayde Davey</b>, Chairperson, <b>Lean Built Afrika, South Africa</b>
      `,
  },
  {
    time: "11:40 - 11:55",
    title: "Starting the BIM Journey for Building Owners",
    description: `
    This session will outline a pathway towards BIM for Building Owners - where to start and how to prioritize your activities to ensure the project outcomes you want.<br/><br/>
    SPEAKER: <b>Simon Bromfield</b>, Territory Sales Manager – Africa, <b> Autodesk, South Africa</b>
      `,
  },
  {
    time: "11:55 - 12:00",
    title: "CLOSING REMARKS",
    description: `
    <b>Rory Green</b>, Channel Lead, <b>Autodesk, South Africa</b>
    `,
  },
];

export default Agenda;
