import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";

import Footer from "components/Footer.js";
import Sponsor from "components/Sponsor.js";

import { sponsors } from "./content";
import { Container, Row, Col } from "reactstrap";

function ThankYou({
  match: {
    params: { id },
  },
}) {
  return (
    <>
      <Navbar />

      <ProfilePageHeader title={`THANK YOU `} bgColor="#fff" />
      <div
        className="section profile-content"
        style={{ padding: 0, background: "#f07434" }}
      >
        <Container>
          <Row>
            <Col lg={12} className="text-center text-white p-5 m-5">
              <h3>
                {id === "REGISTER" && (
                  <>
                    Thank you for your interest in Enterprise Cloud & Data
                    Center Forum. A member of our team will be in touch with you
                    for further details about the event.
                  </>
                )}
                {id === "BROCHURE" && (
                  <>
                    Thank you for your interest in Enterprise Cloud & Data
                    Center Forum. A member of our team will be in touch with you
                    for further details about the event.
                  </>
                )}
                {id === "SPONSOR" && (
                  <>
                    Thank you for your interest in Enterprise Cloud & Data
                    Center Forum. A member of our team will be in touch with you
                    for further details about the event.
                  </>
                )}
                {id === "REPORT" && (
                  <>
                    Thank you for your interest in Enterprise Cloud & Data
                    Center Forum. A member of our team will be in touch with you
                    for further details about the event.
                  </>
                )}
              </h3>
            </Col>
          </Row>
        </Container>
      </div>

      <Sponsor sponsors={sponsors} />
      <Footer />
    </>
  );
}

export default ThankYou;
