import React from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Input,
  Form,
  Alert,
  Spinner,
  Label,
} from "reactstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import countries from "./countries";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

function Register({ type }) {
  const [details, setDetails] = React.useState({
    type,
  });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [other, setOther] = React.useState(false);

  const onChangeDetails = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const onChangeSoftware = (e) => {
    if (e.target.value === "other") {
      setOther(true);
    } else {
      setDetails({ ...details, [e.target.name]: e.target.value });
    }
  };

  const checkboxChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.checked });
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    setSuccess(false);
    // const reg = /[a-zA-Z0-9]*@[gmail|yahoo|hotmail]+\.[com|co.in]+/;
    // if (reg.test(details.email)) {
    //   setLoading(false);
    //   setError("Please enter your business email only");
    //   return null;
    // }
    if (!details.policy) {
      setLoading(false);
      setError("Please accept our privacy policy");
      return null;
    }
    if (!details.country) {
      setLoading(false);
      setError("Please select a country from the dropdown");
      return null;
    }
    fetch("https://us-central1-futureofcc-270e6.cloudfunctions.net/api/email", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
    })
      .then((res) => {
        console.log(res);
        if (res.ok) return res;
        else throw new Error(res);
      })
      .then(() => {
        setLoading(false);
        setSuccess(
          "Thank you. We have received your request, our team will get back to you shortly."
        );
      })
      .catch((err) => {
        setLoading(false);
        setError("Something went wrong please try again");
      });
  };

  return (
    <>
      <div
        className="section"
        style={{
          backgroundColor: "#0696d7",
        }}
      >
        <Container className="my-auto text-white text-400 p-2">
          <Row className="justify-content-center">
            <Col lg={8}>
              {type === "REGISTER" && <p></p>}
              <Form onSubmit={onSubmitForm} className="register-form">
                <Row className="justify-content-center text-white">
                  <Col lg={3} className="my-2">
                    <label>First Name*</label>
                    <Input
                      placeholder="First Name"
                      type="text"
                      name="first_name"
                      onChange={onChangeDetails}
                      required
                    />
                  </Col>
                  <Col lg={3} className="my-2">
                    <label>ِLast Name*</label>
                    <Input
                      placeholder="Last Name"
                      type="text"
                      name="last_name"
                      onChange={onChangeDetails}
                      required
                    />
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>Office Email*</label>
                    <Input
                      placeholder="Email"
                      type="text"
                      name="email"
                      onChange={onChangeDetails}
                      required
                    />
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>Phone*</label>
                    <PhoneInput
                      country={"za"}
                      inputProps={{
                        name: "phone",
                        required: true,
                        autoFocus: true,
                      }}
                      inputClass="w-100"
                      dropdownClass="text-dark"
                      value={details.phone}
                      onChange={(phone) => {
                        setDetails({ ...details, phone });
                      }}
                    />
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>Job Title*</label>
                    <Input
                      placeholder="Job Title"
                      type="text"
                      name="job"
                      onChange={onChangeDetails}
                      required
                    />
                  </Col>

                  <Col lg={6} className="my-2">
                    <label>Company*</label>
                    <Input
                      placeholder="Company"
                      type="text"
                      name="company"
                      onChange={onChangeDetails}
                      required
                    />
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>Country *</label>
                    <Typeahead
                      onChange={(country) => {
                        setDetails({ ...details, country: country[0] });
                      }}
                      placeholder="Country"
                      options={countries}
                    />
                  </Col>
                  {type === "REGISTER" && (
                    <>
                      <Col lg={12} className="my-2">
                        <label>
                          What are current project-related challenges you face?
                          *
                        </label>
                        <Input
                          type="select"
                          name="challenge"
                          onChange={onChangeDetails}
                          required
                        >
                          <option value="">Select</option>
                          {challenge.map((c) => (
                            <option key={c}>{c}</option>
                          ))}
                        </Input>
                      </Col>
                      <Col lg={12} className="my-2">
                        <label>
                          What is your role in the acquiring of new technology?
                          *
                        </label>
                        <Input
                          type="select"
                          name="role"
                          onChange={onChangeDetails}
                          required
                        >
                          <option value="">Select</option>
                          {role.map((c) => (
                            <option key={c}>{c}</option>
                          ))}
                        </Input>
                      </Col>
                      <Col lg={12} className="my-2">
                        <label>
                          What is the document collaboration software are you
                          using for your Project documents and file sharing? *
                        </label>
                        <Input
                          type="select"
                          name="software"
                          onChange={onChangeSoftware}
                          required
                        >
                          <option value="">Select</option>
                          {software.map((c) => (
                            <option key={c}>{c}</option>
                          ))}
                          <option value="other">Other</option>
                        </Input>
                      </Col>
                      {other && (
                        <Col lg={12} className="my-2">
                          <Input
                            type="text"
                            name="software"
                            onChange={onChangeDetails}
                            required
                          />
                        </Col>
                      )}
                      <Col lg={12} className="my-2">
                        <label>What is your company profile *</label>
                        <Input
                          type="select"
                          name="profile"
                          onChange={onChangeDetails}
                          required
                        >
                          <option value="">Select</option>
                          {profile.map((c) => (
                            <option key={c}>{c}</option>
                          ))}
                        </Input>
                      </Col>
                    </>
                  )}
                  <Col lg={12} className="my-2">
                    <div className="ml-4">
                      <Label check>
                        <Input
                          type="checkbox"
                          name="news"
                          onChange={checkboxChange}
                        />{" "}
                        I would like to opt-in to receive Industry News, Trends,
                        Events, and Offers from Saifee Events and/or Autodesk,
                        as sole sponsor of the event. You can unsubscribe at any
                        time.
                      </Label>
                    </div>
                  </Col>
                  <Col lg={12} className="my-2">
                    <div className="ml-4">
                      <Label check>
                        <Input
                          type="checkbox"
                          name="policy"
                          onChange={checkboxChange}
                        />
                        Protecting your privacy is important to Saifee Events.
                        We collect data directly from you when you will Register
                        for this Virtual event. The information we procure
                        includes your name, organization details, email and
                        phone number which is strictly kept confidential with us
                        and will only be shared with Autodesk that is the sole
                        sponsor of this event, to help them communicate with you
                        their relevant offerings. By clicking submit, I
                        acknowledge the Autodesk{" "}
                        <a
                          href="http://www.autodesk.com/privacy"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "#ffa00f" }}
                          className="text-400"
                        >
                          <b>Privacy </b>
                        </a>
                        Statement
                      </Label>
                    </div>
                  </Col>
                </Row>

                {loading ? (
                  <span>
                    <Spinner className="mt-3" color="warning" size="sm" />
                  </span>
                ) : (
                  <Button
                    block
                    className="btn text-white text-700 mt-3"
                    color="primary"
                    size="lg"
                    type="submit"
                    disabled={loading}
                  >
                    Submit
                  </Button>
                )}
              </Form>
            </Col>
          </Row>
        </Container>
        <CreateAlert success={success} error={error} />
      </div>
    </>
  );
}

function CreateAlert({ success, error }) {
  const color = success ? "success" : "danger";
  const message = success ? success : error;
  if (!success && !error) {
    return "";
  } else {
    return (
      <Alert
        color={color}
        isOpen={true}
        fade={true}
        className="text-center"
        style={{ marginTop: "1rem" }}
      >
        {message}
      </Alert>
    );
  }
}

export default Register;

const challenge = [
  "No challenge",
  "Project delays",
  "Ineffective cost control",
  "Project rework",
  "Lack of data sharing",
  "All of the above",
];

const role = ["Influencer", "Approver", "Budget Holder and Signatory"];

const software = ["BIM 360", "Dropbox", "ProjectWise", "Nothing"];

const profile = [
  "Building Owner/Developers",
  "Architects ",
  "Engineering ",
  "Facilities Management ",
  "Contractor",
];
