import React from "react";

import { Container, Row, Col, Card, Modal, Button } from "reactstrap";
import SpeakerModal from "components/SpeakerModal.js";
import Slider from "react-slick";
import ShowMoreText from "react-show-more-text";

function SpeakerGrid() {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;
  const [open, toggleOpen] = React.useState({
    open: false,
    speaker: {},
  });
  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "p-10",
  };

  const updateModal = (e, speaker) => {
    e.preventDefault();
    toggleOpen({ open: !open.open, speaker: speaker });
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });

  function SpeakerCard({ speaker }) {
    return (
      <a href="/" onClick={(e) => updateModal(e, speaker)}>
        <Card
          className="card-background-image mx-auto p-3"
          style={{
            borderRadius: 0,
            boxShadow: "none",
            background: "transparent",
          }}
        >
          <Container fluid>
            <Row>
              <Col xs={12} className="px-0">
                <img
                  src={require(`assets/speakers/${speaker.image}`)}
                  alt="speaker"
                  width="100%"
                  style={{ borderRadius: 0 }}
                />
              </Col>

              <Col xs={12} className="px-0">
                <div className="d-flex justify-content-between">
                  <div>
                    <p className="text-700 mt-2 mb-0 d-block px-0">
                      {speaker.name}
                    </p>
                    <p className="text-300 m-0">{speaker.title}</p>
                    <p
                      className="text-400 m-0 d-block px-0"
                      // style={{ fontSize: "16px" }}
                    >
                      {speaker.company}
                    </p>
                    <p
                      className="text-400 m-0 d-block px-0 mt-2"
                      style={{ fontSize: "14px" }}
                    >
                      <ShowMoreText
                        /* Default options */
                        lines={3}
                        more="Read more"
                        less="Read less"
                        className="content-css"
                        anchorClass="my-anchor-css-class"
                        expanded={false}
                        width={280}
                      >
                        {speaker.text[0]}
                      </ShowMoreText>
                    </p>
                  </div>
                  {speaker.company_image && (
                    <div>
                      <img
                        src={require(`assets/speakers/${speaker.company_image}`)}
                        alt=""
                        style={{ maxWidth: "100px" }}
                      />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </Card>
      </a>
    );
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section py-5"
        data-parallax={true}
      >
        <div className="filter" />
        <Container>
          <Row>
            <Col lg={12} className="">
              <h1
                className="text-700 text-center mb-5"
                style={{
                  fontSize: size ? "3rem" : "2rem",
                  color: "#0696d7",
                }}
              >
                Our Speakers
              </h1>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={5} className="px-3">
              <a href="/" onClick={(e) => updateModal(e, chairman)}>
                <img
                  src={require(`assets/speakers/${chairman.image}`)}
                  alt="speaker"
                  width="100%"
                  style={{ borderRadius: 0, maxWidth: "400px" }}
                />
              </a>
            </Col>
            <Col lg={6} xs={10}>
              <h3 className="text-700 mb-0" style={{ color: "#ffa00f" }}>
                Event Chairman
              </h3>
              <p className="text-700 mt-2 mb-0 d-block px-0 ">
                {chairman.name}
              </p>
              <p className="text-300 m-0">{chairman.title}</p>
              <p className="text-400 m-0 d-block px-0">{chairman.company}</p>
              {size ? (
                <p className="text-400 pt-2" style={{ fontSize: "14px" }}>
                  {chairman.text[0]}
                </p>
              ) : (
                <p className="text-400 pt-2" style={{ fontSize: "14px" }}>
                  <ShowMoreText
                    /* Default options */
                    lines={3}
                    more="Read more"
                    less="Read less"
                    className="content-css"
                    anchorClass="my-anchor-css-class"
                    expanded={false}
                    width={280}
                  >
                    {chairman.text[0]}
                  </ShowMoreText>
                </p>
              )}
            </Col>
          </Row>
          <Row className="justify-content-center mt-4">
            <Col lg={6} xs={10} className={!size && "order-2"}>
              <h3 className="text-700 mb-0" style={{ color: "#ffa00f" }}>
                Keynote Speaker
              </h3>
              <p className="text-700 mt-2 mb-0 d-block px-0 ">{keynote.name}</p>
              <p className="text-300 m-0">{keynote.title}</p>
              <p className="text-400 m-0 d-block px-0">{keynote.company}</p>
              {size ? (
                <p className="text-400 pt-2" style={{ fontSize: "14px" }}>
                  <ShowMoreText
                    /* Default options */
                    lines={3}
                    more="Read more"
                    less="Read less"
                    className="content-css"
                    anchorClass="my-anchor-css-class"
                    expanded={false}
                    width={1500}
                  >
                    {keynote.text[0]}
                  </ShowMoreText>
                </p>
              ) : (
                <p className="text-400 pt-2" style={{ fontSize: "14px" }}>
                  <ShowMoreText
                    /* Default options */
                    lines={3}
                    more="Read more"
                    less="Read less"
                    className="content-css"
                    anchorClass="my-anchor-css-class"
                    expanded={false}
                    width={280}
                  >
                    {keynote.text[0]}
                  </ShowMoreText>
                </p>
              )}
            </Col>
            <Col lg={5} className="px-3 order-xs-1">
              <a href="/" onClick={(e) => updateModal(e, keynote)}>
                <img
                  src={require(`assets/speakers/${keynote.image}`)}
                  alt="speaker"
                  width="100%"
                  style={{ borderRadius: 0, maxWidth: "400px" }}
                />
              </a>
            </Col>
          </Row>
          <Row className="py-4">
            {size ? (
              speakers.map((speaker, index) => (
                <Col className="mx-auto px-0" lg={4} xs={12} key={index}>
                  <SpeakerCard speaker={speaker} />
                </Col>
              ))
            ) : (
              <Col xs={11} className={"mx-auto"}>
                <Slider {...settings}>
                  {speakers.map((speaker, index) => (
                    <SpeakerCard speaker={speaker} />
                  ))}
                </Slider>
              </Col>
            )}
          </Row>
          <Row className="justify-content-center">
            <Col lg={3} className="text-center px-1">
              <Button
                href="/recording"
                className="rounded-0 text-400 text-white"
                color="primary"
                size="lg"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span
                  style={{
                    color: "#fff",
                    textTransform: "none",
                    fontWeight: 400,
                    fontSize: "20px",
                  }}
                >
                  View Event Recording
                </span>
              </Button>
            </Col>
          </Row>
        </Container>

        <Modal isOpen={open.open} toggle={(e) => updateModal(e)} style={{}}>
          <div className="section profile-content">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => updateModal(e)}
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                color: "#888888",
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
            <SpeakerModal speaker={open.speaker} />
          </div>
        </Modal>
      </div>
    </>
  );
}

export default SpeakerGrid;

export const speakers = [
  {
    name: "Simon Bromfield",
    title: "Territory Sales Manager – Africa",
    company: "Autodesk, South Africa",
    text: [
      `Simon Bromfield has a deep passion for Africa and helping people turn their dreams into reality by understanding a customer’s business and identifying the correct solution to meet their needs. He is an avid lover of the outdoors, with interests spanning hiking, fly fishing, mountain biking and running. 
    `,
    ],
    image: "simon.jpg",
  },
  {
    name: "Dr Calayde Davey",
    title: "Chairperson",
    company: "Lean Built Afrika, South Africa",
    text: [
      `Calayde Davey holds a PhD in Environmental Planning & Design (USA), and Masters Degree in Architecture (ZAR). She has worked on high-rise construction in South-East Asia, Lean Project and BIM facilitations in high-performing sustainable real estate in the USA. Calayde is a Research Associate with the University of Pretoria, South Africa. Here she leads the trans-disciplinary African Digital Twin Cities Initiative, and the introduction of Lean Design & Construction practices, methods, and education in multidisciplinary built environment tertiary education programs. She is also the director of Regen50 - Urban Strategies company based in South Africa, and the co-chair of Lean Built Environment-Afrika — a non-profit organization bringing Lean Design & construction practices to continental Africa.
    `,
    ],
    image: "Calayde.jpg",
  },
  {
    name: "Vijay Raina",
    title: "Senior Technical Sales Specialist – AEC",
    company: "Autodesk, UAE",
    text: [
      `Vijay Raina is a Technical Specialist for AEC at Autodesk. With over 15 years industry experience in India, Middle East and South East Asia, Vijay is a Mechanical Engineer with extensive hands-on experience and has been AEC technical specialist for Autodesk for over 4 years. He worked as BIM Manager-MEP for Tilke in Bahrain prior to joining Autodesk.
    `,
    ],
    image: "vijay.jpg",
  },
  {
    name: "Richard Matchett",
    title: "Digital Practice Lead",
    company: "Zutari, South Africa",
    text: [
      `
      Richard Matchett is a professional engineer with over 20 years’ experience in the built
environment in South Africa, Greater Africa and the Middle East.
He is the digital lead at Zutari, responsible for their digital strategy and transformation to
adapt our engineering practise during the ever-changing times we live in. Richard is
passionate about working smart, being paper-less and raising the bar for the way our
industry delivers solutions. He believes the implementation of BIM in the built environment
is a fundamental enabler of achieving smart infrastructure and buildings solutions.
Richard is a problem solver and sense maker. He delights in making the complex simple and
uses this approach in his leadership of our digital strategy. Richard believes in using
technology as a tool to enable human ingenuity. He is excited to see how the industry
transforms to adopt modern approaches to work and project delivery.
    `,
    ],
    image: "Richard.jpg",
  },
  {
    name: "Robert Laubscher",
    title: "Principal Civil Engineer - Engineering Services Unit",
    company: "Corporate Services, City of Cape Town, South Africa",
    text: [
      `Robert is a professional civil engineer and member of the City of Cape Town’s Engineering Services Unit under the Corporate Services directorate. The specialised unit is responsible for various strategic interventions that drive the improvement of project planning and execution in the City. Robert leads an internal team tasked with the strategic planning for BIM implementation. 
      He is a registered Professional Engineer with over 18 years’ industry experience spanning over three continents. His project track record includes bridges, tunnels, buildings and city developments in the UAE, UK and South Africa. 
      Robert has a keen interest in the application of modern technological advancements in the civil engineering and building industry.
      `,
    ],
    image: "robert.png",
  },
  {
    name: "Thomas Fuller",
    title: "Property Development",
    company: "Attacq Ltd, South Africa",
    text: [
      `Thomas is now the Senior Development Manager at Attacq Ltd for a Smart City role out, implementation and delivery of the property development pipeline of a mixed use precinct of 1,700,000m2at Waterfall City, Midrand, Johannesburg, South Africa <a href="http://waterfallcity.co.za/">http://waterfallcity.co.za/</a> still currently under development.<br/>
    He was formerly a Professional Quantity Surveyor for 7 years with Davis Langdon (now AECOM) Thomas was involved primarily with project delivery of Industrial, Process, Manufacture and Plant role out for Toyota City, Prospection, South Africa and headed up the Quantity Surveying Operations for the Transnet HMG JV, Durban Port Expansion and Harbour Widening Projects. His responsibilities included overall project / risk management of full project lifecycle, deal completion and security perfection, Risk reporting, BASEL Regulatory Framework Modelling for 9 years before joining Attacq Properties.&nbsp;`,
    ],
    image: "thomas.jpg",
  },
  // {
  //   name: "Taz-Wynne Superman",
  //   title: "Associate",
  //   company: "Paragon Architects, South Africa",
  //   text: [
  //     `Hailing from a family of tradespeople, Taz-wynne was drawn to technical creativity from an early age. With a full understanding of the hard work and dedication it takes to achieve goals, he graduated in 2010 with a B.Tech Architecture from the University of Johannesburg.
  //   <br/>After building his career as an architectural technologist at AHD Architects, he started at Paragon in 2010 as a Junior Technologist. The experience he has accumulated over the last decade has equipped him for his current role as an Associate, Project Senior Architectural Technologist, and Head of BIM (Building Information Modelling). He also holds a degree in Property Development and Management; and graduated from the University of the Witwatersrand with his Post Graduate Diploma in 2016.
  //   <br/>Taz-wynne strives for quality and consistency in his work ethic. With ever-changing BIM technologies, he understands the need to stay ahead of the game for the benefit of the practice and its clients. He has exceptional organisational skills, which have contributed significantly to the effectiveness of his team. He is also able to use market studies to match project design to tenant attributes and needs – which allows him to contribute more meaningfully to the development process.
  //   `,
  //   ],
  //   image: "taz.jpg",
  // },
  {
    name: "Jarred Pincus",
    title: "Associate",
    company: "Paragon Group, South Africa",
    text: [``],
    image: "jarred.jpg",
  },
  {
    name: "Marek Suchocki",
    title: "Infrastructure Industry Engagement Lead",
    company: "Autodesk, UK",
    text: [
      `Marek Suchocki works with Autodesk customers and their project teams helping drive change through the adoption of digital solutions and processes. He holds a degree in Civil Engineering, is a Chartered Engineer, Chartered IT Professional, Fellow of the British Computer Society, Fellow of the Institution of Civil Engineers (ICE) and a Member of the Chartered Institution of Civil Engineering Surveyors (CICES)… Marek sits on the CICES Geospatial Engineering Practices Committee, the ICE Digital Transformation Panel, buildingSMART UK & Ireland Management Committee and International InfraRoom Steering Committee, represents Autodesk at the UK BIM Alliance and is a member of the British Standards committee that authored the UK and ISO 19650 BIM standards.
    `,
    ],
    image: "marek.jpg",
  },
];

export const chairman = {
  name: "Rory Green",
  title: "Channel Manager-Africa",
  company: "Autodesk, South Africa",
  text: [
    `Rory’s passion for technology started at the age of 10 years
  old when his dad brought home a ZX Spectrum computer from an
  overseas business trip. Since that moment his fascination with
  technology has never ended. Having studied IT at Witwatersrand
  Tech (now known as University of Johannesburg), he started his
  career following a technical path at an IT reseller. Further
  education included Microsoft MCSE, Compaq ASE as well as an
  Program for Management Development from the Gordon Institute
  of Business Sciences (GIBS) in 2008. He has been fortunate in
  his 20+ year career to work for some great companies such as
  COMPAQ, Hewlett Packard and Cisco in sales, product management
  and team management positions (including 2 years in London in
  the early 2000’s). Rory joined Autodesk in July 2020 in the
  role of channel manager responsible for Africa.`,
  ],
  image: "rory.png",
};

export const keynote = {
  name: "Tasneem Motara",
  title: "Gauteng Member of the Executive Committee",
  company: " Infrastructure Development & Property Management, South Africa",
  text: [
    `Ms. Tasneem Motara is the Gauteng Member of Executive Committee for Infrastructure Development and Property Management. She is also a member of the Provincial Executive Committee of the African National Congress. 
    Ms. Motara served as a Member of Parliament in the 5th Administration, having served in the National Council of Provinces (NCOP) representing Gauteng as a Provincial Whip. Her responsibility as an MP was to liaise between the Gauteng Provincial Legislature and Parliament, as well as lead the Provincial delegation of Permanent Delegates. She also served in various roles as a Whip in the multi-party caucus, as well as Whip of both Select Committees on Finance and Appropriations. 
    During this tenure she also served as a Member of the SADC Parliamentary Forum as a member of the Trade, Infrastructure, Finance and Investment Standing Committee, and was elected to its Executive Committee for the period 2016 – 2018.
    Starting her activism in the ANC at the age of 20, Ms. Motara has led the ANC and the ANCYL in Benoni in various roles. Leading the Youth League up to a Provincial level in the Executive Committee up to 2013, she has also led the ANC in Ekurhuleni, serving in its Regional Executive Committee from 2014 – 2018. She has subsequently been elected to serve in the ANC Provincial Executive Committee, where she currently is a member of the working committee and is the Spokesperson.
    `,
  ],
  image: "tasneem.jpg",
};
