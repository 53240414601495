import React from "react";

function ProfilePageHeader({ title, bgColor }) {
  let pageHeader = React.createRef();

  const padding =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth > 1024
      ? "5rem 0 0rem 0rem"
      : "5rem 0 1rem 0rem";
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: bgColor,
          padding: padding,
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        {title ? (
          <h1
            className="text-700 text-center"
            style={{
              fontSize: size ? "3rem" : "2rem",
              color: "#0696d7",
            }}
          >
            {title}
          </h1>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default ProfilePageHeader;
