// reactstrap components
import { Container, Col, Row, Button } from "reactstrap";

import React from "react";

function LandingPageHeader({ home }) {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 50 ||
        document.body.scrollTop > 50
      ) {
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <div
      style={{
        backgroudColor: "#fff",
        background: `url(${require("assets/images/background.jpg")})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: size ? "center" : "38% 20%",
        backgroundSize: "cover",
        paddingTop: "8rem",
      }}
      className="section"
    >
      <div className="overlay-primary"></div>
      <Container style={{ marginTop: !size && "-1rem" }}>
        <Row className="align-items-center">
          <Col md={6} xs={12}>
            <h1
              className="text-400  text-dark m-0"
              style={{
                fontSize: size ? "2.5rem" : "1.5rem",
                lineHeight: size ? "2.5rem" : "2.5rem",
              }}
            >
              <span className="text-dark text-700">SOUTH AFRICA:</span> FUTURE
              OF
            </h1>
            <img
              src={require("assets/images/asset.png")}
              alt="connected"
              width="100%"
              style={{ paddingTop: size && "16px" }}
            />
            <br />
            <h1
              className="text-400 text-dark mt-3"
              style={{ fontSize: size ? "3.2rem" : "1.9rem" }}
            >
              CONSTRUCTION 2021
            </h1>
            <h1
              className="text-400 text-white m-0 mt-2 p-3"
              style={{
                fontSize: size ? "2rem" : "1.45rem",
                background: "#0696d7",
                width: size ? "440px" : "330px",
              }}
            >
              26 May 2021 | Virtual Event
              <br />
              10:00 AM – 12:00 PM SAST
            </h1>
            <h1
              className="text-400 text-dark mt-3"
              style={{ fontSize: size ? "1.8rem" : "1.3rem" }}
            >
              <i>
                Connecting <b>Building Owners</b>
                <br /> to Processes and Technology
              </i>
            </h1>
            <div className="container-fluid row mt-3">
              <div className="col-6 order-2">
                <p className="text-dark text-400">Organised By</p>
                <img
                  src={require(`assets/images/logo/dark.png`)}
                  width="100%"
                  style={{ maxWidth: "200px" }}
                  alt="logo"
                />
              </div>
              <div className="col-6 order-xs-1">
                <p className="text-dark text-400">Sponsored By</p>
                <img
                  src={require(`assets/images/logo/autodesk.png`)}
                  width="100%"
                  style={{ maxWidth: "200px" }}
                  alt="logo"
                />
              </div>
            </div>
            <div className="container-fluid row mt-3">
              <Col lg={12} className="pt-3 px-0">
                <Button
                  href="/recording"
                  className="rounded-0 navbar-item-custom text-400 text-white px-4"
                  color="primary"
                  size="lg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span
                    style={{
                      color: "#fff",
                      textTransform: "none",
                      fontSize: "20px",
                    }}
                  >
                    View Event Recording
                  </span>
                </Button>{" "}
              </Col>
            </div>
          </Col>

          <Col md={6} xs={12} className="mt-4"></Col>
        </Row>
      </Container>
    </div>
  );
}

export default LandingPageHeader;
