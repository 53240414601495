import React from "react";

import { Container, Row, Col, Card } from "reactstrap";

function Sponsor() {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          <h1
            className="text-700 text-center"
            style={{
              fontSize: size ? "3rem" : "2rem",
              color: "#0696d7",
            }}
          >
            Partners
          </h1>
          {Object.keys(sponsors).map((sponsor, index) => (
            <React.Fragment key={index}>
              <Col lg={12}>
                <h3 className="text-center text-400 text-muted pb-3">
                  {sponsors[sponsor].length > 1 ? `${sponsor}` : sponsor}
                </h3>
                <Container fluid>
                  <Row>
                    {sponsors[sponsor].map((data, i) => (
                      <Col lg={4} className={"mx-auto"} key={i}>
                        <a href={data.link}>
                          <Card style={{ borderRadius: 0 }}>
                            <img
                              src={require(`assets/sponsors/${data.image}`)}
                              className="mx-auto"
                              alt={data.name}
                              width="100%"
                              style={{ borderRadius: 0 }}
                            />
                          </Card>
                        </a>
                      </Col>
                    ))}
                  </Row>
                </Container>
              </Col>
            </React.Fragment>
          ))}
        </Container>
      </div>
    </>
  );
}

export default Sponsor;
export const sponsorsP = [
  {
    name: "Autodesk",
    pageLink: "Autodesk",
    type: "Sponsored By",
    image: "Autodesk.png",
    text: [""],
    link: "https://www.autodesk.co.za",
  },

  {
    name: "CIOB",
    pageLink: "CIOB",
    type: "Supporting Associations",
    image: "CIOB.png",
    text: [``],
    link: "https://www.ciob.org/about",
    grid: 3,
  },
  {
    name: "SAIBPP",
    pageLink: "saibpp",
    type: "Supporting Associations",
    image: "saibpp.png",
    text: [``],
    link: "https://saibpp.co.za/",
    grid: 3,
  },
  {
    name: "CMFoundation",
    pageLink: "cmfoundation",
    type: "Supporting Associations",
    image: "cmfoundation.png",
    text: [``],
    link: "https://cmfoundation.org.za/",
    grid: 3,
  },
  // {
  //   name: "BIMCOMMUNITY",
  //   pageLink: "BIMCOMMUNITY",
  //   type: "Supporting Associations",
  //   image: "bim.png",
  //   text: [``],
  //   link: "https://www.bimcommunity.com/",
  //   grid: 3,
  // },
];

const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const groupByType = groupBy("type");
export const sponsors = groupByType(sponsorsP);
